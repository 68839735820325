import React, { Component } from "react";

// import { SwiperSlide } from "swiper/react";
// import { Swiper } from "swiper/react";
import Promotion, { PromotionProps } from "./Promotion";
import { get } from "@/redux/module/api";
import dynamic, { Loader } from "next/dynamic";
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination, Autoplay } from "swiper";
import { SwiperProps } from "swiper/react";

import SwiperCore from "swiper";
import "swiper/css"; // Swiper의 CSS 파일을 import
import "swiper/css/navigation"; // Navigation 모듈의 CSS 파일을 import
import "swiper/css/pagination"; // Pagination 모듈의 CSS 파일을 import
import "swiper/css/effect-coverflow"; // Coverflow 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-fade"; // Fade 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-flip"; // Flip 효과 모듈의 CSS 파일을 import
import "swiper/css/effect-cube"; // Cube 효과 모듈의 CSS 파일을 import
import { ProductListPropsType } from "@/types/ProductTypes";

// import SwiperCore, { Navigation, Pagination } from "swiper";
const DynamicSwiper = dynamic<SwiperProps>(
  () => import("swiper/react").then((module) => module.Swiper),
  { ssr: false }
);
// const DynamicPagination = dynamic(
//   () => import("swiper").then((module) => module.Pagination),
//   { ssr: false }
// );

// const DynamicCore = dynamic(
//   () => import("swiper").then((module) => module.SwiperCore),
//   { ssr: false }
// );

class PromotionSlide extends Component<
  { type?: string; promotionData?: any[] },
  { resData: any[] }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      resData: [],
    };
    SwiperCore.use([Pagination, Autoplay]);
    // SwiperCore.use([Autoplay]);SwiperCore.use([Navigation, Pagination]);
  }

  componentDidMount = async () => {
    let res = null;
    if (this.props.type === "product") {
      let tmpList: Array<{
        id: number;
        title: string;
        subTitle: string;
        thumbnailImgUrl: string;
        color: string;
        link: string;
      }> = [];

      if (Array.isArray(this.props.promotionData)) {
        tmpList = this.props.promotionData
          .sort(() => Math.random() - 0.5) // 배열을 랜덤하게 섞기
          .slice(0, 5) // 배열의 첫 5개 요소만 선택
          .map((data: any) => ({
            id: data.id,
            title: `${data.animeName}\n${data.name}`,
            subTitle: "",
            thumbnailImgUrl: data.thumbnailImgUrl,
            color: "#ffffff",
            link: `/product/detail/${data.id}`,
          }));
      }
      this.setState({
        resData: tmpList,
      });
    } else {
      res = await get("post/promotion", {});
      if (res.status === 200) {
        this.setState({
          resData: res.data.resData,
        });
      }
    }
  };
  render() {
    // SwiperCore.use([Autoplay, DynamicPagination]);
    // Pagination 모듈을 등록
    // SwiperCore.use([Pagination]);
    return (
      <div
        style={{
          margin: "0",
          padding: "0",
        }}
      >
        {this.state.resData.length > 0 ? (
          <DynamicSwiper
            pagination={true}
            modules={[Pagination]}
            className="mySwiper"
            style={{
              height: "auto",
              padding: 0,
              margin: 0,
              width: "100%",
              background: "transparent",
            }}
            autoplay={{ delay: 3000 }}
          >
            <Swiper>
              {this.state.resData.map(
                (promotion: PromotionProps, index: number) => {
                  return (
                    <SwiperSlide
                      key={index}
                      style={{
                        padding: 0,
                        margin: 0,
                        background: "transparent",
                        width: "100%",
                      }}
                    >
                      <Promotion
                        id={promotion.id}
                        title={promotion.title}
                        subTitle={promotion.subTitle}
                        thumbnailImgUrl={promotion.thumbnailImgUrl}
                        color={promotion.color}
                        //예약상품 임시삭제
                        link={promotion.link}
                        // link={"#"}
                        type={this.props.type}
                      />
                    </SwiperSlide>
                  );
                }
              )}
            </Swiper>
          </DynamicSwiper>
        ) : (
          <div>프로모션 없음</div>
        )}
      </div>
    );
  }
}

export default PromotionSlide;
